<template>
  <v-container fluid>
    <v-row>
      <v-col md="6" cols="12">
        <div class="conOfHeader">
          <div class="headerOfPage">Project Tasks</div>
        </div>
      </v-col>
    </v-row>

    <v-row v-if="listData && listData.length > 0">
      <v-col cols="12">
        <section class="conOfProjects">
          <div class="conOfTable">
            <v-data-table
              :headers="headers"
              :items="listData"
              hide-default-footer
            >
              <template v-slot:[`item.name`]="{ item }">
                <router-link
                  :to="{
                    name: 'projectAttendancebyID',
                    params: { project_id: item.project_id, task_id: item.id },
                  }"
                >
                  {{ item.name }}
                </router-link>
              </template>
              <template v-slot:[`item.tags`]="{ item }">
                <div v-for="tag in item.tags" :key="tag.name">
                  {{ tag.name }}
                </div>
              </template>
            </v-data-table>
          </div>
        </section>
        <div class="conOfPagination pr-5 pt-5">
          <v-pagination
            v-model="pagination.current_page"
            :total-visible="7"
            :length="pagination.last_page"
            @input="goToPage(pagination.current_page)"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
    <div class="loaderContainer" v-else-if="isLoading">
      <v-progress-circular
        :size="120"
        :width="4"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <v-row v-else>
      <v-col cols="12" class="mt-7">
        <EmptyState />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ApiService } from "@/modules/shared/services/api";
let apiServices = new ApiService();
import EmptyState from "@/modules/shared/components/emptystate";

export default {
  data: () => ({
    pagination: {
      current_page: 1,
    },
    isLoading: false,
    headers: [
      { text: "Name", value: "name" },
      { text: "project Name", value: "project_name" },
      { text: "Description", value: "description" },

      { text: "Created by", value: "created_by" },
      { text: "From", value: "from" },
      { text: "To", value: "to" },
      { text: "tags", value: "tags" },
    ],
    listData: [],
  }),
  components: { EmptyState },
  computed: {},
  methods: {
    goToPage(page) {
      this.getListData(page);
      window.scrollTo(0, 0);
    },
    getListData(page) {
      this.listData = [];
      this.isLoading = true;
      apiServices
        .get(
          `project-tasks?per_page=7&page=${page}&project_id=${this.$route.params.id}`
        )
        .then((res) => {
          if (res) {
            if (res.data.length > 0) {
              this.isLoadingSearch = false;
              this.listData = res.data;
              console.log("ListData", this.listData);
              this.pagination = res.meta;
              console.log("pagination", this.pagination);
            }
            this.isLoading = false;
          } else this.isLoading = false;
        });
    },
  },
  created() {
    this.getListData(this.pagination.current_page);
  },
  watch: {},
};
</script>
<style lang="scss" scoped></style>
